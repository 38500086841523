<template>
  <v-app dark>
    <v-navigation-drawer app v-if="user" :clipped="true" v-model="drawer" width="325px" style="max-height: calc(100% - 64px - 48px)" :mobile-breakpoint="0">
      <v-sheet>
        <v-toolbar flat v-if="showMenu">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="drawer = false" icon v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-chevron-double-left
                </v-icon>
              </v-btn>
            </template>
            <span>Inchide</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toggleDashboard" icon :color="!compare && !aggregate ? 'primary' : 'default'" v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-tablet-dashboard
                </v-icon>
              </v-btn>
            </template>
            <span>Dashboard</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toggleCompare" icon :color="compare ? 'primary' : 'default'" v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-select-compare
                </v-icon>
              </v-btn>
            </template>
            <span>Compara</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toggleAgregate" icon :color="aggregate ? 'primary' : 'default'" v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-file-chart-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Agregate</span>
          </v-tooltip>
        </v-toolbar>
        <template v-if="!showMenu">
          <v-list-item class="px-2" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>
                mdi-chevron-double-left
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                Inchide
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-2" @click="toggleDashboard" :color="!compare && !aggregate ? 'primary' : 'default'">
            <v-list-item-icon>
              <v-icon>
                mdi-tablet-dashboard
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Dashboard
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-2" @click="toggleCompare" :color="compare ? 'primary' : 'default'">
            <v-list-item-icon>
              <v-icon>
                mdi-select-compare
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Compara
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-2" @click="toggleAgregate" :color="aggregate ? 'primary' : 'default'">
            <v-list-item-icon>
              <v-icon>
                mdi-file-chart-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Agregate
            </v-list-item-content>
          </v-list-item>
        </template>
        <CIFListComponent :disabled="dnd" v-if="cifList && showMenu"/>
        <TreeComponent :disabled="dnd" v-if="!cifList && showMenu"/>
      </v-sheet>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-left
      v-if="user"
      class="pl-2"
      height="64px">
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-avatar @mouseover="stopAttentionSeeking">
          <img src="/img/tud_group_logo.png" alt="TUD Group Logo">
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-icon class="slide" v-if="attentionSeeking">
        mdi-chevron-left
      </v-icon>
      <v-toolbar-title shrink-on-scroll class="ml-5">
        TUD's Analytics
      </v-toolbar-title>
      <v-spacer></v-spacer>
      {{ user.username }}
      <v-btn @click="logout" v-if="user" icon>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view @toggleDrawer.once="toggleDrawer"/>
    </v-main>

    <FooterComponent />
  </v-app>
</template>

<script>
import FooterComponent from '@/components/Footer'
import TreeComponent from '@/components/Tree'
import CIFListComponent from '@/components/CIFList'

export default {
  name: 'App',

  components: {
    FooterComponent,
    TreeComponent,
    CIFListComponent
  },

  data: () => ({
    drawer: false,
    attentionSeeking: false
  }),

  computed: {
    user () {
      return this.$store.getters.user
    },
    cifList () {
      return this.user ? !!this.user.cifuri.length : true
    },
    activeCif () {
      return this.$store.getters.activeCif
    },
    dnd () {
      return this.$store.getters.dnd
    },
    compare () {
      return this.$store.getters.compare
    },
    aggregate () {
      return this.$store.getters.aggregate
    },
    showMenu () {
      return this.comnpare || !this.aggregate
    }
  },

  watch: {
    user (newVal, oldVal) {
      if (oldVal && !newVal) this.$router.push({ name: 'Index' })
    },
    activeCif () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = false
      }
    }
  },

  methods: {
    logout () {
      this.$store.dispatch('logout')
    },

    toggleDrawer () {
      setTimeout(() => {
        this.drawer = true

        setTimeout(() => {
          this.drawer = false

          this.attentionSeeking = true
        }, 1000)
      }, 1000)
    },
    stopAttentionSeeking () {
      this.attentionSeeking = false
    },

    toggleCompare () {
      this.$store.commit('toggleCompare')
    },
    toggleAgregate () {
      this.$store.commit('toggleAgregate')
    },
    toggleDashboard () {
      this.$store.commit('toggleDashboard')
    }
  },

  mounted () {
    this.$store.dispatch('getLanguages')
    this.$store.dispatch('fetchUI')
  }
}
</script>

<style lang="stylus">
  .slide {
    animation: 250ms linear 0s infinite alternate slide-animation;
  }

  @keyframes slide-animation {
    from {
      margin-left: 32px
      padding-right: 12px
      scale: 1.5
    }

    to {
      margin-left: 12px
      padding-right: 32px
      scale: 0.75
    }
  }

  .ob-hero {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.7) 0%, rgba(0,96,100,0.5) 100%), url('~/public/img/hero.jpg')
    // background-image: linear-gradient(135deg, rgba(97,97,97,0.7) 0%, rgba(51,51,61,0.5) 100%), url('~/public/img/hero.jpg')

    background-size: 100%, cover
  }

  .ob-login {
    // background-image: url('~/public/img/user-nou-card.jpg')
    // background-size: cover

    .ob-login-links {
      text-decoration: none
    }
  }
</style>
