<template>
  <IndexComponent />
</template>

<script>
import IndexComponent from '@/components/Index.vue'

export default {
  name: 'IndexView',
  components: {
    IndexComponent
  }
}
</script>
