<template>
  <v-footer app>
    <v-spacer></v-spacer>
    <span>&copy; {{ (new Date()).getFullYear() }} - Verde Smart Data</span>

    <v-spacer></v-spacer>

    <v-menu top>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <template v-if="lang === 'en'">
            <flag iso="us" :squared="false" />
            /
            <flag iso="gb" :squared="false" class="mr-2" />
          </template>
          <template v-else>
            <flag :iso="lang" :squared="false" class="mr-2" />
          </template>
          {{ lang }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="l in langs" :key="l" @click="setLang(l)">
          <v-list-item-title class="text-uppercase">{{ l }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',

  computed: {
    lang () {
      return this.$store.getters.dictionaryLang
    },
    langs () {
      return this.$store.getters.dictionaryLanguages
    }
  },

  methods: {
    setLang (lang) {
      this.$store.commit('setLang', lang)
    }
  }
}
</script>
