<script>
export default {
  name: 'TranslateMixin',

  computed: {
    dictionarUI () {
      return this.$store.getters.dictionaryUi
    }
  },
  methods: {
    translateUI (term, language) {
      /* eslint-disable no-mixed-operators */
      return this.dictionarUI && this.dictionarUI[term] && this.dictionarUI[term][language] || term
    }
  }
}
</script>
