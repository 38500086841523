<template>
  <div>
    <v-text-field
      @input="setSearch"
      :value="search"
      :disabled="disabled"
      label="Cautare"
      flat
      hide-details
      clearable
      clear-icon="mdi-close-circle-outline">
    </v-text-field>
    <v-list dense :disabled="disabled">
      <template v-for="judet of items">
        <v-subheader class="text-capitalize" :key="judet.denumire">
          <span v-if="judet.denumire !== 'municipiul bucuresti'">Judetul &nbsp;</span>
          {{ judet.denumire }}
        </v-subheader>
        <v-list-item v-for="item in judet.children" :key="item.id" @click="toggleActive(item.id)" :disabled="disabled">
          <v-list-item-icon>
            <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-if="item.judet === 'MUNICIPIUL BUCURESTI'">
              {{ item.typeLabel === 'sector' ? `mdi-numeric-${item.name.split(' ')[1]}-circle-outline` : 'mdi-alpha-b-circle' }}
            </v-icon>
            <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'judet'">mdi-shield-crown</v-icon>
            <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'municipiu'">mdi-city-variant-outline</v-icon>
            <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'oras'">mdi-home-city-outline</v-icon>
            <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'comuna'">mdi-home-silo-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span :class="{'font-weight-bold': active.includes(item.id)}">{{ item.name }}</span>
            </v-list-item-title>
            <v-list-item-subtitle class="text-capitalize">
              {{ item.typeLabel }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action :title="disabledCheckbox(item.id) ? `Pueti alege maxim ${maxCifuri} elemente pentru comparatie.` : 'Includeti/Excludeti elementul in/din lista de comparatie.'">
            <v-scale-transition origin="center">
              <v-checkbox
                v-if="compare"
                v-model="compareList"
                :value="item.id"
                :disabled="disabledCheckbox(item.id)">
              </v-checkbox>
            </v-scale-transition>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'

export default {
  name: 'CIFListComponent',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    cifuri: [],
    judete: [],
    active: [],
    search: null,
    maxCifuri: Infinity
  }),

  computed: {
    items () {
      return this.judete.map(judet => ({
        denumire: judet.toLowerCase(),
        children: this.cifuri.filter(cif => {
          if (this.search) {
            return cif.name.toLowerCase().includes(this.search.toLowerCase()) && cif.judet === judet
          } else {
            return cif.judet === judet
          }
        }).sort((a, b) => a.name.localeCompare(b.name))
      })).filter(judet => judet.children.length)
    },
    compare () {
      return this.$store.getters.compare
    },
    compareList: {
      get () {
        return this.$store.getters.compareList.map(cif => cif.cif)
      },
      set (value) {
        this.$store.dispatch('toggleCifInCompare', value)
      }
    },
    compareProgress () {
      return this.$store.getters.compareProgress
    }
  },

  methods: {
    disabledCheckbox (id) {
      return (this.compareList.length === this.maxCifuri && !this.compareList.includes(id)) || (this.compareList.includes(id) && this.loadingCif(id))
    },
    loadingCif (id) {
      const cifIndexes = Object.keys(this.compareProgress).filter(index => index.startsWith(id))

      return cifIndexes.reduce((acc, index) => {
        if (this.compareProgress[index].loading) acc = true

        return acc
      }, false)
    },
    setSearch: _debounce(function (value) {
      this.search = value
    }, 500),

    toggleActive (id) {
      if (this.compare) return
      this.active = this.active.includes(id) ? [] : [id]

      this.$store.commit('setActiveCif', this.active)
    }
  },

  async created () {
    try {
      const parseTypeLabel = cif => {
        const lcType = cif.tip_apl.toLowerCase()
        let label = ''
        if (lcType === 'judet') {
          label = 'consiliu judetean'
        } else {
          if (cif.judet.toLowerCase() === 'municipiul bucuresti') {
            if (cif.denumire_apl === 'Municipiul Bucuresti') {
              label = 'municipiu'
            } else {
              label = 'sector'
            }
          } else {
            label = lcType
          }
        }

        return label
      }

      const cifuri = await this.$store.dispatch('fetchCifuri')

      this.cifuri = cifuri
        .map(cif => ({
          id: cif.cif_op,
          name: cif.denumire_apl,
          type: cif.tip_apl.toLowerCase(),
          typeLabel: parseTypeLabel(cif),
          ...cif
        }))

      this.judete = this.cifuri.reduce((acc, item) => {
        if (!acc.includes(item.judet)) acc.push(item.judet)

        return acc
      }, []).sort((a, b) => {
        if (a.toLowerCase() === 'municipiul bucuresti') return -1
        else if (b.toLowerCase() === 'municipiul bucuresti') return 1
        else return a.localeCompare(b, 'ro')
      })
    } catch (err) {
      console.log('Error loading CIF-uyri. ', err)
    }
  }
}
</script>
