<template>
  <v-sheet>
    <v-text-field
      @input="setSearch"
      :value="search"
      :disabled="disabled"
      label="Cautare"
      flat
      hide-details
      clearable
      clear-icon="mdi-close-circle-outline">
    </v-text-field>
    <v-treeview
      :disabled="disabled"
      :active.sync="active"
      :activatable="!compare"
      open-all
      :load-children="fetch"
      :items="items"
      :search="search"
      selected-color="primary"
      :open-on-click="false"
      :open.sync="open"
      expand-icon="mdi-chevron-down"
      color="primary"
      dense
      hoverable
      shaped>
      <template v-slot:prepend="{ item }">
        <template v-if="item.type === 'all'">RO</template>
        <v-icon v-else-if="item.type === 'judet'">
          {{ item.name.toLowerCase() === 'municipiul bucuresti' ? 'mdi-alpha-b-circle' : 'mdi-shield-crown' }}
        </v-icon>
        <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'cj'">mdi-bank</v-icon>
        <v-icon v-else-if="item.type === 'municipiu-folder'">mdi-city-variant</v-icon>
        <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'municipiu'">mdi-city-variant-outline</v-icon>
        <v-icon v-else-if="item.type === 'oras-folder'">mdi-home-city</v-icon>
        <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'oras'">mdi-home-city-outline</v-icon>
        <v-icon v-else-if="item.type === 'comuna-folder'">mdi-home-silo</v-icon>
        <v-icon :size="active.includes(item.id) ? '28px' : '24px'" :color="active.includes(item.id) ? 'primary': 'gray'" v-else-if="item.type === 'comuna'">mdi-home-silo-outline</v-icon>
      </template>
      <template v-slot:label="{ item }">
        <span :class="{'font-weight-bold': active.includes(item.id)}">{{ item.name }}</span>
      </template>
      <template v-slot:append="{ item }">
        <v-checkbox
          @click.stop
          v-if="compare && !isFolder(item)"
          v-model="compareList"
          :value="item.id"
          hide-details
          class="mt-0"
          :disabled="disabledCheckbox(item.id)">
        </v-checkbox>
      </template>
    </v-treeview>
  </v-sheet>
</template>

<script>
import _debounce from 'lodash.debounce'

export default {
  name: 'TreeComponent',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    open: [],
    active: [],
    cifuri: [],
    judete: [],
    search: null
  }),

  computed: {
    items () {
      const children = this.judete.map(judet => ({
        id: `Judet_${judet}`,
        name: judet,
        type: 'judet',
        children: this.getChildren(judet)
      }))

      return [{
        id: 1,
        name: 'Toate APL-urile',
        children,
        type: 'all'
      }]
    },
    compare () {
      return this.$store.getters.compare
    },
    compareList: {
      get () {
        return this.$store.getters.compareList.map(cif => cif.cif)
      },
      set (value) {
        this.$store.dispatch('toggleCifInCompare', value)
      }
    },
    compareProgress () {
      return this.$store.getters.compareProgress
    }
  },

  watch: {
    active (value, oldValue) {
      if (value && String(value[0]).includes('_')) {
        const tip = value[0].split('_')[0]
        if (['Municipii', 'Orase', 'Comune'].includes(tip)) {
          if (oldValue && oldValue[0]) {
            this.active = [oldValue[0]]
          } else {
            this.acive = []
          }
          this.open.push(value[0])
          return
        }
      }

      if (value[0] && value[0] === 1) {
        this.active = []
        return
      }

      if (value[0] && value[0].startsWith('Judet')) {
        const idJudet = value[0]
        const numeJudet = idJudet.split('_')[1]
        let typeToSearch = 'Judet'

        if (numeJudet.toLowerCase() === 'municipiul bucuresti') {
          typeToSearch = 'Municipiu'
        }
        const judet = this.cifuri.find(cif => cif.tip_apl === typeToSearch && cif.denumire_apl.toLowerCase() === numeJudet.toLowerCase())
        this.active = [judet.cif_op]

        if (!this.open.includes(idJudet)) {
          this.open.push(idJudet)
          if (!this.open.includes(1)) this.open.push(1)
        }
      }

      this.$store.commit('setActiveCif', this.active)
    },
    cifuri (val) {
      this.judete = val.reduce((acc, cif) => {
        const judet = cif.judet

        if (!acc.includes(judet)) acc.push(judet)

        return acc
      }, []).sort((a, b) => {
        if (a.toLowerCase() === 'municipiul bucuresti') return -1
        else if (b.toLowerCase() === 'municipiul bucuresti') return 1
        else return a.localeCompare(b, 'ro')
      })
    }
  },

  methods: {
    setSearch: _debounce(function (value) {
      this.search = value
    }, 500),
    fetch () {
      if (this.cifuri.length) return

      return this.$store.dispatch('fetchCifuri')
        .then(result => {
          this.cifuri = result
        })
        .catch(err => {
          console.log(err)
        })
    },
    getChildren (judet) {
      if (judet.toLowerCase() !== 'municipiul bucuresti') {
        const cj = this.cifuri.find(cif => cif.tip_apl === 'Judet' && cif.denumire_apl.toLowerCase() === judet.toLowerCase())

        const municipii = [{
          id: `Municipii_${judet}`,
          name: 'Municipii',
          type: 'municipiu-folder',
          children: []
        }]
        const orase = [{
          id: `Orase_${judet}`,
          name: 'Orase',
          type: 'oras-folder',
          children: []
        }]
        const comune = [{
          id: `Comune_${judet}`,
          name: 'Comune',
          type: 'comuna-folder',
          children: []
        }]

        for (const cif of this.cifuri) {
          if (cif.judet !== judet) continue

          switch (cif.tip_apl) {
            case 'Municipiu': {
              municipii[0].children.push({
                ...cif,
                id: cif.cif_op,
                type: 'municipiu',
                name: cif.denumire_apl
              })
              break
            }
            case 'Oras': {
              orase[0].children.push({
                ...cif,
                id: cif.cif_op,
                type: 'oras',
                name: cif.denumire_apl
              })
              break
            }
            case 'Comuna': {
              comune[0].children.push({
                ...cif,
                id: cif.cif_op,
                type: 'comuna',
                name: cif.denumire_apl
              })
              break
            }
            default: continue
          }
        }

        municipii[0].children.sort((a, b) => {
          return a.denumire_apl.localeCompare(b.denumire_apl, 'ro')
        })
        orase[0].children.sort((a, b) => {
          return a.denumire_apl.localeCompare(b.denumire_apl, 'ro')
        })
        comune[0].children.sort((a, b) => {
          return a.denumire_apl.localeCompare(b.denumire_apl, 'ro')
        })

        return [{
          id: cj.cif_op,
          name: `CJ ${cj.denumire_apl}`,
          type: 'cj',
          denumire_apl: cj.denumire_apl,
          capita: cj.capita,
          tip_apl: cj.tip_apl,
          judet: cj.judet
        }].concat(municipii).concat(orase).concat(comune)
      } else {
        const bucuresti = []
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'municipiul bucuresti'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 1'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 2'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 3'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 4'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 5'))
        bucuresti.push(this.cifuri.find(cif => cif.tip_apl === 'Municipiu' && cif.denumire_apl.toLowerCase() === 'sectorul 6'))

        return bucuresti.map(item => {
          return {
            id: item.cif_op,
            name: item.denumire_apl,
            type: 'municipiu',
            ...item
          }
        })
      }
    },
    disabledCheckbox (id) {
      return (this.compareList.length === this.maxCifuri && !this.compareList.includes(id)) || (this.compareList.includes(id) && this.loadingCif(id))
    },
    loadingCif (id) {
      const cifIndexes = Object.keys(this.compareProgress).filter(index => index.startsWith(id))

      return cifIndexes.reduce((acc, index) => {
        if (this.compareProgress[index].loading) acc = true

        return acc
      }, false)
    },
    toggleCompare () {
      this.$store.commit('toggleCompare')
    },
    isFolder (item) {
      return item.type.includes('folder') || item.type === 'judet' || item.type === 'all'
    }
  }
}
</script>
