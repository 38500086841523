<template>
  <v-container fluid class="ob-hero fill-height pa-6">
    <v-row no-gutters>
      <v-col>
        <h1 class="primary--text display-2">
          <img src="/img/logo_big.png" alt="TUD's Analytics logo" style="max-height: 20vh; max-width: 80vw">
        </h1>
      </v-col>
    </v-row>

    <v-row class="align-stretch flex-nowrap" no-gutters>
      <v-col style="max-width: 600px">
        <v-row>
          <v-col class="mt-0 pt-0">
            <p class="primary--text title font-weight-light" style="text-align: center" v-html="translateUI('LandingHero_1', lang)">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-card class="transparent elevation-0">
              <v-card-title class="title primary--text pa-0" style="justify-content: center" v-html="translateUI('LandingHero_2', lang)">
              </v-card-title>
              <v-card-text class="pt-4">
                <v-scroll-x-transition group hide-on-leave>
                  <v-card v-show="slide === 1" :key="1" class="transparent elevation-0">
                    <v-card-text class="primary--text pa-0">
                      <v-row class="align-center" style="min-height: 120px" no-gutters>
                        <v-col sm="12" md="3" style="font-size: 70px">
                          <i class="flaticon-graph secondary--text"></i>
                        </v-col>
                        <v-col sm="12" md="9" class="" v-html="translateUI('LandingSlider_1', lang)">
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card v-show="slide === 2" :key="2" class="transparent elevation-0">
                    <v-card-text class="primary--text pa-0">
                      <v-row class="align-center" style="min-height: 120px">
                        <v-col sm="12" md="3" style="font-size: 70px" xs="12">
                          <i class="flaticon-chart-1 secondary--text"></i>
                        </v-col>
                        <v-col sm="12" md="9" class="" v-html="translateUI('LandingSlider_2', lang)" xs="12">
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card v-show="slide === 3" :key="3" class="transparent elevation-0">
                    <v-card-text class="primary--text pa-0">
                      <v-row class="align-center" style="min-height: 120px">
                        <v-col sm="12" md="3" style="font-size: 70px" xs="12">
                          <i class="flaticon-report secondary--text"></i>
                        </v-col>
                        <v-col sm="12" md="9" class="" v-html="translateUI('LandingSlider_3', lang)" xs="12">
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-scroll-x-transition>
              </v-card-text>
              <v-card-actions>
                <v-row wrap>
                  <v-col cols="12">
                    <v-progress-linear height="1" :value="progressValue" :color="progressColor"></v-progress-linear>
                  </v-col>
                  <v-col cols="12" class="justify-center d-flex">
                    <v-spacer></v-spacer>
                    <v-btn v-for="i in 3" :key="i" text icon small @click="slide = i" color="primary">
                      <v-icon small>{{ slide === i ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align-content-center class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" large @click="showComponent('login')">LOGIN</v-btn>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="justify-center align-start d-flex" style="position: absolute; right: 0; left: 0">
      <v-scroll-x-transition hide-on-leave>
        <ob-login v-if="show.login" @close-login="close('login')"></ob-login>
      </v-scroll-x-transition>
    </div>

  </v-container>
</template>

<script>
import Login from '@/components/Login'
import TranslateMixin from '@/mixins/Translate'

export default {
  name: 'IndexComponent',
  mixins: [TranslateMixin],
  components: {
    'ob-login': Login
  },
  data: () => ({
    slide: 1,
    timer: null,
    elapsed: 0,
    show: {
      login: false
    }
  }),
  computed: {
    user () {
      return this.$store.getters.user
    },
    progressValue () {
      return Math.ceil(this.elapsed * 100 / 6000)
    },
    progressColor () {
      return ['primary', 'warning', 'error'][Math.floor(this.progressValue / 40)]
    },
    dictionarUI () {
      return this.$store.getters.dictionaryUi
    },
    lang () {
      return this.$store.getters.dictionaryLang
    }
  },
  methods: {
    showComponent (type) {
      switch (type) {
        case 'login': {
          this.show.login = true
          break
        }
      }
    },
    close (type) {
      switch (type) {
        case 'login': {
          this.show.login = false
          break
        }
      }
    },
    nextSlide () {
      this.elapsed += 100
      if (this.elapsed >= 6000) {
        this.elapsed = 0
        this.slide++
        if (this.slide > 3) this.slide = 1
      }
    }
  },
  mounted () {
    this.timer = setInterval(() => (this.nextSlide()), 100)
    if (this.user) {
      this.$router.push('Home')
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
