import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import FlagIcon from 'vue-flag-icon'
import GmapVue from 'gmap-vue'

Vue.use(FlagIcon)
Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: process.env.NODE_ENV === 'production' ? 'AIzaSyAwcwMN9EQa7g-SB-YvB5LjAReKrgCFG6g' : 'AIzaSyAnTNcqP-gWdB7amD8NfzVerbPhcjHoEkA'
  },

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
